.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 4px;
  background: linear-gradient(to bottom, #795548 0%, #8D6E63 100%);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  border-radius: 2px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  width: 50%;
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline-item.left {
  left: 0;
}

.timeline-item.right {
  left: 50%;
}

.timeline-marker {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 4px solid #795548;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  transition: all 0.3s ease;
  box-shadow: 0 0 0 4px rgba(121, 85, 72, 0.2);
}

.timeline-item:hover .timeline-marker {
  background-color: #795548;
  box-shadow: 0 0 0 8px rgba(121, 85, 72, 0.2);
}

.timeline-item.left .timeline-marker {
  right: -52px;
}

.timeline-item.right .timeline-marker {
  left: -52px;
}

.timeline-content {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.timeline-item:hover .timeline-content {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15) !important;
}

.timeline-image-container {
  margin: -20px -20px 20px -20px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.timeline-image {
  width: 100%;
  height: 350px; /* Increase the height */
  object-fit: cover;
  transition: transform 0.3s ease;
}

.timeline-item:hover .timeline-image {
  transform: scale(1.05);
}

.timeline-date {
  color: #795548;
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.timeline-title {
  color: #2c3e50 !important;
  margin: 10px 0 !important;
  font-size: 1.4em !important;
}

.timeline-text {
  color: #34495e;
  line-height: 1.6;
  font-size: 1.1em;
}

@media screen and (max-width: 768px) {
  .timeline-container::after {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item.right {
    left: 0;
  }
  
  .timeline-item.left .timeline-marker,
  .timeline-item.right .timeline-marker {
    left: 18px;
  }
  
  .timeline-image {
    height: 200px;
  }
}

.timeline-year {
  text-align: center;
  position: relative;
  margin: 2em 0;
  z-index: 2;
}

.timeline-year span {
  background: #795548;
  color: white;
  padding: 0.5em 2em;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: bold;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
